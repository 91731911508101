@import './common/common.css';
@import '~antd/dist/antd.css';
@import './assets/css/index.css';
.braft-output-content {
	max-width: 1260px;
}
.braft-output-content span {
	max-width: 1260px;
	word-wrap: break-all;
	white-space: break-spaces;
}
.braft-output-content p {
	max-width: 1260px;
	word-wrap: break-all;
	white-space: break-spaces;
}